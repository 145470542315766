import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Navbar = styled.div`
  position: fixed;
  left: 0;
  top: 5rem;
  bottom: 0;
  z-index: 1;
  width: 7vw;
  background-color: #081782;
  .navbar-container {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e6e6e6;
  }
  nav {
    transform: translate(0, 100%) rotate(-90deg);
    display: flex;
    flex-direction: row-reverse;
  }
  a {
    text-decoration: none;
    padding: 1vw 2vh;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    white-space: nowrap;
    display: flex;
    height: 4vw;
    line-height: 4vw;
    margin: 1vw 1vw 0;
    border-radius: 5px 5px 0 0;
    &.active {
      background: rgba(223, 224, 224, 1);
      color: black;
    }
  }
`;

const Nav = props => {
  const { children, pageTitle, path = "", location } = props;

  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      className="navbar w-nav"
    >
      <Navbar>
        <nav role="navigation">
          <Link
            to="/blog"
            className={
              path.indexOf("/blog") > -1 || path.indexOf("/article/") > -1
                ? "active"
                : ""
            }
          >
            Blog
          </Link>

          {/* <Link to="/records" className={path === "/records" ? "active" : ""}>
              Records
            </Link> */}
          {/* <Link to="/resume" className={path === "/records" ? "active" : ""}>
              Professional
            </Link> */}
          <Link
            to="/contact/"
            className={path.indexOf("/contact") > -1 ? "active" : ""}
          >
            Contact
          </Link>
          <Link
            to="/about"
            className={path.indexOf("/about") > -1 ? "active" : ""}
          >
            Mark WIE?
          </Link>
        </nav>
      </Navbar>
    </div>
  );
};

export default Nav;
