import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import styled from "styled-components";

import { FirebaseContext, useAuth } from "../components/Firebase";
import Seo from "../components/seo";
import messagesInEnglish from "../locales/en.json";
import messagesInDutch from "../locales/nl.json";
import GlobalStyle from "../styles/GlobalStyle";
import BlogNavigation from "./blog/navigation";
import Header from "./header";
import Nav from "./nav";

const StyledContainer = styled.div`
  display: flex;
  > main {
    width: 93vw;
    margin-left: 7vw;
    margin-top: 5rem;
    min-height: 90vh;
    transition: width 0.5s;
    @media print {
      margin-left: 0;
      width: 100%;
      margin-top: 3rem;
    }
  }
`;

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { children, pageTitle, path = "", location } = props;
  const { user, firebase, loading } = useAuth();
  const isBlog = path.indexOf("/article") > -1;
  const slug = props.data?.mdx?.slug;

  let userLocale, domain;
  if (typeof window !== "undefined") {
    userLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    domain = location.hostname;
  }

  if (!userLocale) {
    userLocale = "nl";
  }

  const messages =
    userLocale.indexOf("nl") > -1 ? messagesInDutch : messagesInEnglish;

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading, userLocale }}>
      <IntlProvider messages={messages} locale={userLocale} defaultLocale="nl">
        <Seo
          title={`${pageTitle || "Home"} || ${data.site.siteMetadata?.title}`}
        />
        <GlobalStyle />
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          domain={domain}
        />
        <Nav location={location} />
        <StyledContainer>
          <main>{children}</main>
          {isBlog && <BlogNavigation slug={slug || ""} />}
        </StyledContainer>
      </IntlProvider>
    </FirebaseContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
