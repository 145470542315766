exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-mdx-slug-js": () => import("./../../../src/pages/article/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-article-mdx-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-records-js": () => import("./../../../src/pages/records.js" /* webpackChunkName: "component---src-pages-records-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-worldcup-2022-admin-js": () => import("./../../../src/pages/worldcup2022/admin.js" /* webpackChunkName: "component---src-pages-worldcup-2022-admin-js" */),
  "component---src-pages-worldcup-2022-index-js": () => import("./../../../src/pages/worldcup2022/index.js" /* webpackChunkName: "component---src-pages-worldcup-2022-index-js" */),
  "component---src-pages-worldcup-2022-match-js": () => import("./../../../src/pages/worldcup2022/match.js" /* webpackChunkName: "component---src-pages-worldcup-2022-match-js" */),
  "component---src-pages-worldcup-2022-rules-js": () => import("./../../../src/pages/worldcup2022/rules.js" /* webpackChunkName: "component---src-pages-worldcup-2022-rules-js" */),
  "component---src-pages-worldcup-2022-schedule-js": () => import("./../../../src/pages/worldcup2022/schedule.js" /* webpackChunkName: "component---src-pages-worldcup-2022-schedule-js" */),
  "component---src-pages-worldcup-2022-schedule-pre-wc-js": () => import("./../../../src/pages/worldcup2022/schedule-pre-wc.js" /* webpackChunkName: "component---src-pages-worldcup-2022-schedule-pre-wc-js" */),
  "component---src-pages-worldcup-2022-standings-js": () => import("./../../../src/pages/worldcup2022/standings.js" /* webpackChunkName: "component---src-pages-worldcup-2022-standings-js" */)
}

