import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  collectionGroup,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import firebaseConfig from "./config";

const firebaseApp = initializeApp(firebaseConfig);

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      this.auth = getAuth(firebaseApp);
      this.db = getFirestore(firebaseApp);
      this.functions = getFunctions(firebaseApp);

      if (!!process.env.FIRESTORE_EMULATOR_HOST) {
        connectFirestoreEmulator(this.db, "localhost", 8080);
        connectAuthEmulator(this.auth, "http://localhost:9099");
        connectFunctionsEmulator(this.functions, "localhost", 5001);
      }
      // update these later -- not used yet:
      // this.storage = app.storage();
    }
  }

  /**********************
   *                    *
   * ACCOUNT MANAGEMENT *
   *                    *
   **********************/

  async login({ email, password }) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async logout() {
    await this.auth.signOut();
  }

  async register({ username, email, password }) {
    const newUser = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );

    await setDoc(doc(this.db, "publicProfiles", email), {
      userId: newUser.user.uid,
      email,
      name: username,
    });
  }

  signInWithGoogle() {
    const provider = new GoogleAuthProvider();

    return signInWithPopup(this.auth, provider)
      .then(async result => {
        const { user: { uid, email, displayName, photoURL } = {} } = result;
        const profile = await this.getUserProfile(email);
        if (!profile) {
          console.log("creating profile for", email);
          setDoc(
            doc(this.db, "publicProfiles", email),
            {
              userId: uid,
              email,
              name: displayName,
              photoURL,
            },
            { merge: true }
          );
        }
        return result;
      })
      .catch(error => {
        return error;
      });
  }

  async getPublicProfiles() {
    const q = query(collection(this.db, "publicProfiles"));
    const querySnapshot = await getDocs(q);
    const snapshotProfiles = [];
    querySnapshot.forEach(doc => {
      snapshotProfiles.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return snapshotProfiles;
  }

  async getUserProfile(userEmail) {
    const docRef = doc(this.db, "publicProfiles", userEmail);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log("No such document!");
      return null;
    }
    return docSnap.data();
  }

  async getProfileByUid(uid) {
    const profilesRef = collection(this.db, "publicProfiles");
    const q = query(profilesRef, where("userId", "==", uid));

    const querySnapshot = await getDocs(q);
    let profile;
    querySnapshot.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      profile = doc.data();
    });
    return profile;
  }

  /**********************
   *                    *
   * BLOG SPECIFIC      *
   *                    *
   **********************/

  subscribeToBlogPostComments({ slug, onSnap }) {
    const blogpostRef = doc(this.db, "blog", slug);
    const commentsRef = collection(this.db, "comments");
    const q = query(commentsRef, where("blogPost", "==", blogpostRef));
    return onSnapshot(q, onSnap);
  }

  async postComment({ text, slug }) {
    const postCommentCallable = httpsCallable(this.functions, "postComment");
    return postCommentCallable({
      text,
      slug,
    }).then(result => {
      console.log(result);
    });
  }

  /**********************
   *                    *
   * WORLD CUP SPECIFIC *
   *                    *
   **********************/

  async getSchedule() {
    const q = query(collection(this.db, `wc2022-schedule`), orderBy("DateUtc"));
    const querySnapshot = await getDocs(q);
    const snapshotSchedule = [];
    querySnapshot.forEach(doc => {
      snapshotSchedule.push(doc.data());
    });
    return snapshotSchedule;
  }
  subscribeToPredictions({ email, onSnap }) {
    const predictionsRef = collection(
      this.db,
      `wc2022-predictions/${email}/scores`
    );
    const q = query(predictionsRef);
    return onSnapshot(q, onSnap);
  }

  async getProfilePredictions(email) {
    const q = query(collection(this.db, `wc2022-predictions/${email}/scores`));
    const querySnapshot = await getDocs(q);
    const snapshotPredictions = {};
    querySnapshot.forEach(doc => {
      const matchData = doc.data();
      snapshotPredictions[matchData.matchNumber] = matchData;
    });
    return snapshotPredictions;
  }

  async prefillPredictions() {
    const prefillPredictionsCallable = httpsCallable(
      this.functions,
      "prefillPredictions"
    );
    return prefillPredictionsCallable();
  }

  async updatePrediction(matchNumber, key, value) {
    const email = this.auth.currentUser.email;
    const payload = {
      matchNumber,
      lastUpdated: new Date(),
    };
    payload[key] = value;
    const scoresRef = doc(
      this.db,
      `wc2022-predictions/${email}/scores`,
      String(matchNumber)
    );
    setDoc(scoresRef, payload, { merge: true }).then(async () => {
      const scoreSnap = await getDoc(scoresRef);
      if (!scoreSnap.exists()) {
        console.log("No such document!");
      }
      const scoreData = scoreSnap.data();
      let scoreCompleteness = 0;
      if (matchNumber < 49) {
        if (
          typeof scoreData.homePrediction !== "undefined" &&
          !isNaN(scoreData.homePrediction)
        ) {
          scoreCompleteness += 33;
        }
        if (
          typeof scoreData.awayPrediction !== "undefined" &&
          !isNaN(scoreData.awayPrediction)
        ) {
          scoreCompleteness += 33;
        }
        if (
          typeof scoreData.answer !== "undefined" &&
          scoreData.answer !== "-"
        ) {
          scoreCompleteness += 34;
        }
      } else if (matchNumber < 65) {
        if (
          typeof scoreData.homePrediction !== "undefined" &&
          !isNaN(scoreData.homePrediction)
        ) {
          scoreCompleteness += 20;
        }
        if (
          typeof scoreData.awayPrediction !== "undefined" &&
          !isNaN(scoreData.awayPrediction)
        ) {
          scoreCompleteness += 20;
        }
        if (
          typeof scoreData.answer !== "undefined" &&
          scoreData.answer !== "-"
        ) {
          scoreCompleteness += 20;
        }
        if (
          scoreData.homeCountry &&
          scoreData.homeCountry.indexOf("#") === -1
        ) {
          scoreCompleteness += 20;
        }
        if (
          scoreData.awayCountry &&
          scoreData.awayCountry.indexOf("#") === -1
        ) {
          scoreCompleteness += 20;
        }
      } else {
        if (scoreData.answer !== "wc2022_65w" && scoreData.answer.length) {
          scoreCompleteness += 100;
        }
      }
      setDoc(scoresRef, { scoreCompleteness }, { merge: true });
    });
  }

  async submitScore(payload) {
    const updatePredictionCallable = httpsCallable(
      this.functions,
      "updateResult"
    );
    return updatePredictionCallable(payload).then(result => {
      return result;
    });
  }

  async getStandings(matchNumber) {
    const docRef = doc(this.db, "wc2022-schedule", `/${matchNumber}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().standings;
    } else {
      console.log("No such document!");
      return {};
    }
  }

  async getPredictionsForMatch(matchNumber) {
    const querySnapshot = await getDocs(
      query(
        collectionGroup(this.db, "scores"),
        where("matchNumber", "==", parseInt(matchNumber, 10))
      )
    );
    const predictions = [];
    querySnapshot.forEach(doc => {
      const matchData = doc.data();
      const email = doc._key.path.segments[6];
      predictions.push({
        email,
        answer: matchData.answer,
        home: matchData.homePrediction,
        away: matchData.awayPrediction,
        homeCountry: matchData.homeCountry || null,
        awayCountry: matchData.awayCountry || null,
      });
    });
    return predictions;
  }

  async getLastUpdated() {
    const docRef = doc(this.db, "wc2022-info", "info");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return {};
    }
  }

  async runAfterUpdatingResults(matchNumberToUpdate) {
    const lastUpdated = await this.getLastUpdated();

    // 1. get all profiles that have everything filled out
    const profilesSnap = await getDocs(
      query(
        collection(this.db, "publicProfiles"),
        where("percentagePredicted", "==", "100.00")
      )
    );

    // grab previous match, so we can retrieve standings[email] for each profile
    const previousMatchSnap = await getDoc(
      doc(this.db, `wc2022-schedule/${lastUpdated.lastMatchUpdated}`)
    );
    // grab current match, so we can update its standings object for each email/profile
    const matchToUpdateRef = doc(
      this.db,
      "wc2022-schedule",
      `${matchNumberToUpdate}`
    );

    // loop over profiles
    profilesSnap.forEach(async p => {
      const profile = p.data();
      const email = profile.email;

      // grab total points as of previous match (if it exists, for robustness)
      const previousTotal =
        previousMatchSnap.exists() && previousMatchSnap.data().standings
          ? previousMatchSnap.data().standings[email]
          : 0;

      // grab points scored for this profile, for this match
      const latestMatchPointsSnap = await getDoc(
        doc(
          this.db,
          `wc2022-predictions/${email}/scores/${matchNumberToUpdate}`
        )
      );
      const pointsScored = latestMatchPointsSnap.data().pointsTotal;

      // new total points scored:
      const totalPoints =
        parseInt(previousTotal, 10) + parseInt(pointsScored, 10);

      // update to Profile info with new total points
      setDoc(
        doc(this.db, "publicProfiles", email),
        { totalPoints },
        { merge: true }
      );

      // update the match's Standings object, adding this profile to it
      const obj = {};
      obj[email] = totalPoints;
      await setDoc(matchToUpdateRef, { standings: obj }, { merge: true });
    });
    // update Last Updated info
    await setDoc(
      doc(this.db, "wc2022-info/info"),
      {
        lastUpdated: new Date(),
        lastMatchUpdated: matchNumberToUpdate,
      },
      { merge: true }
    );
  }
}

/**********************
 *                    *
 * INITIALIZE IT ALL  *
 *                    *
 **********************/

let firebaseInstance;

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase(firebaseApp);
    return firebaseInstance;
  } else if (firebaseInstance) {
    return firebaseInstance;
  } else {
    return null;
  }
}

export default getFirebaseInstance;

// KEEPING THIS AS REFERENCE FOR STATIC DATA (NON REALTIME)
// async getBlogPostComments({ slug }) {
//   const q = query(
//     collection(this.db, "comments"),
//     where("blogPost", "==", blogpostRef)
//   );
//   const querySnapshot = await getDocs(q);
//   const snapshotComments = [];
//   querySnapshot.forEach(doc => {
//     snapshotComments.push({
//       id: doc.id,
//       ...doc.data(),
//     });
//   });
//   return snapshotComments;
// }
