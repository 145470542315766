const firebaseConfig = {
  apiKey: "AIzaSyBoE-h2KTNdeDtdNX31y67EwGuV21_17_E",
  authDomain: "markkaternl.firebaseapp.com",
  projectId: "markkaternl",
  storageBucket: "markkaternl.appspot.com",
  messagingSenderId: "868063635671",
  appId: "1:868063635671:web:9ebbeae03b491069292892",
  measurementId: "G-13TZSZZ0JB",
};

export default firebaseConfig;
