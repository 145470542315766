import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    @media print  {
      font-size: 10px;
    }
  }
  body {
    font-family: "Merriweather", Tahoma, sans-serif;
    background: #ccc;
    color: #222;
    font-size: 15px;
    @media print  {
      font-size: 10px;
    }
  }
  h1, p, pre {
    margin-bottom: 1rem;
  }
  h2, h3 {
    margin: 1rem 0;
  }
  h1 {
    font-size: 1.85rem;
    letter-spacing: -1px;
  }
  h2 {
    font-size: 1.7rem;
    letter-spacing: -1px;

  }
  h3 {
    font-size: 1.5rem;
    font-weight: normal;
  }
  hr {
    margin: 2rem;
    clear: both;
  }
  ul, ol {
    margin: 0 0 1rem 1rem;
  }
  a {
    color: #1d2fae;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .yes,
  .no {
    display: inline-block;
    padding: 1px 0.3rem;
    color: white;
    border-radius: 0.3rem;
    border: 1px solid #333;
    font-family: "Courier";
    @media print {
      color: #000;
    }
  }
  .yes {
    background: #0fac0f;
  }
  .no {
    background: #e73737;
  }
`;

export default GlobalStyle;
