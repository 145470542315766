import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import InstagramSVG from "../../images/instagram.svg";
import VideoSVG from "../../images/video.svg";
import WritingSVG from "../../images/writing.svg";

const Nav = styled.nav`
  width: 29vw;
  height: 88vh;
  overflow: auto;
  padding: 2vw;
  position: fixed;
  right: 0;
  top: 10vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
  a {
    text-decoration: none;
  }
  ul,
  li {
    list-style: none;
    padding: 0;
  }
  li {
    padding-bottom: 1.5rem;
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
  }
  li:last-child {
    border: 0px;
    padding-bottom: 0;
  }
  li:before {
    content: "";
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid #4e5ed3;
    box-shadow: 3px 3px 0px #bab5f8;
    box-shadow: 3px 3px 0px #bab5f8;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 0px;
  }
  li.entries {
    border-left: none;
    &:before {
      display: none;
    }
  }
  li.active-year {
    border-left-color: transparent;
    &:before {
      background: #4e5ed3;
    }
  }
  li.entries .entry {
    background: white;
    padding: 5px;
    border: 1px solid #4e5ed3;
    border-radius: 5px;
    color: #2a2839;
    &:hover {
      background-color: #b6b6d1;
      cursor: pointer;
      &:before {
      }
    }
  }
  li.entries .entry:hover::before {
    background: red;
  }
  li.active-entry {
    .entry {
      background: #4e5ed3;
      color: #fff;
      p,
      a {
        color: #fff;
      }
      svg {
        fill: #fff;
      }
    }
    &:before {
      background: #4e5ed3;
    }
  }
  p {
    color: #4f4f4f;
    font-size: 0.8rem;
    line-height: 1.5;
    margin: 0;
  }
  svg {
    width: 1rem;
    fill: grey;
    margin: 0 0.2rem 0 0;
    float: right;
  }
`;

const BlogNavigation = ({ theme, slug }) => {
  const [activeYear, setActiveYear] = useState(theme);
  const [hasUpdatedScroll, setHasUpdatedScroll] = useState(false);

  const activeElement = useRef();

  useEffect(() => {
    if (activeElement.current && !hasUpdatedScroll) {
      activeElement.current.scrollIntoView();
      setHasUpdatedScroll(true);
    }
  }, [hasUpdatedScroll]);

  const data = useStaticQuery(graphql`
    query allMdx {
      allMdx(sort: { fields: frontmatter___publishedAt, order: ASC }) {
        nodes {
          frontmatter {
            publishedAt(formatString: "MMMM D, YYYY")
            title
            categories
          }
          id
          slug
        }
      }
    }
  `);
  const numYears = new Date().getFullYear() - 2000;
  const yearsRange = [...Array(numYears + 1).keys()]
    .reverse()
    .map(i => i + 2000);

  const toggleYear = e => {
    e.preventDefault();
    setActiveYear(e.target.innerText);
  };

  const activeYearPosts = data.allMdx.nodes
    .filter(
      node =>
        new Date(node.frontmatter.publishedAt).getFullYear() ===
        parseInt(activeYear, 10)
    )
    .reverse();

  const navigateToEntry = slug => {
    navigate(`/article/${slug}`);
  };

  return (
    <Nav>
      <ul className="nav-list">
        {yearsRange.map(year =>
          parseInt(year, 10) === parseInt(activeYear, 10) ? (
            <>
              <li key="year" className="active-year" ref={activeElement}>
                {activeYear} ({activeYearPosts.length} berichten)
              </li>
              <li key={year} className="entries">
                <ul>
                  {activeYearPosts.length > 0 ? (
                    activeYearPosts.map(node => {
                      // only show links to active year posts
                      const {
                        frontmatter: { publishedAt, title, categories } = {},
                      } = node;
                      if (
                        new Date(publishedAt).getFullYear() ===
                        parseInt(activeYear, 10)
                      ) {
                        return (
                          <li
                            title={title}
                            key={publishedAt}
                            className={node.slug === slug ? "active-entry" : ""}
                            onClick={() => {
                              navigateToEntry(node.slug);
                            }}
                          >
                            <div className="entry">
                              <Link to={`/article/${node.slug}`}>
                                {categories.includes("Instagram") ? (
                                  <InstagramSVG />
                                ) : categories.includes("Video") ? (
                                  <VideoSVG />
                                ) : (
                                  <WritingSVG />
                                )}

                                {title}
                              </Link>
                              <p>
                                {new Date(publishedAt).toLocaleDateString(
                                  "nl-NL",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </p>
                            </div>
                          </li>
                        );
                      }
                    })
                  ) : (
                    <li>No inspiration in {activeYear}. :(</li>
                  )}
                </ul>
              </li>
            </>
          ) : (
            <li key={year}>
              <div className="time">
                <a href="#" onClick={toggleYear}>
                  {year}
                </a>
              </div>
            </li>
          )
        )}
      </ul>
    </Nav>
  );
};

export default BlogNavigation;
