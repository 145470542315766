import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Photo1 from "../../../../src/photos/2000/32.jpg";
import Photo2 from "../../../../src/photos/2000/33.jpg";
import Photo3 from "../../../../src/photos/2000/34.jpg";
import Photo4 from "../../../../src/photos/2000/35.jpg";
import Photo5 from "../../../../src/photos/2000/36.jpg";
import Photo6 from "../../../../src/photos/2000/37.jpg";
import Photo7 from "../../../../src/photos/2000/38.jpg";
import Photo8 from "../../../../src/photos/2000/39.jpg";
import Photo9 from "../../../../src/photos/2000/40.jpg";
import Photo10 from "../../../../src/photos/2000/41.jpg";
import Photo11 from "../../../../src/photos/2000/42.jpg";
import * as React from 'react';
export default {
  AliceCarousel,
  Photo1,
  Photo2,
  Photo3,
  Photo4,
  Photo5,
  Photo6,
  Photo7,
  Photo8,
  Photo9,
  Photo10,
  Photo11,
  React
};