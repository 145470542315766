import React, { useContext } from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

import { FirebaseContext } from "./Firebase";

const StyledHeader = styled.header`
  background: linear-gradient(
    to bottom,
    rgba(242, 157, 57, 1) 0%,
    rgba(242, 157, 57, 1) 15%,
    rgba(255, 132, 50, 1) 50%,
    rgba(242, 157, 57, 1) 85%,
    rgba(242, 157, 57, 1) 100%
  );
  border-bottom: 2px inset black;
  height: 5rem;
  position: fixed;
  width: 100%;
  z-index: 1;
  @media print {
    border: none;
    .home {
      display: none;
    }
  }
  h1 {
    position: absolute;
    top: 0.3rem;
    right: -6.5rem;
    line-height: 5rem;
    font-family: "Courier";
    font-size: 2rem;
    line-height: 2.1rem;
    text-shadow: 1px 1px 1px #f60;
    margin: 0;
    transition: right 0.5s ease-out;
    white-space: pre;
    text-align: right;

    &:hover {
      right: 0;
    }

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    @media print {
      font-size: 20px !important;
      text-shadow: 1px 1px 1px #ccc;
      a {
        font-size: 20px !important;
        color: #000;
      }
      br {
        display: inline-block;
      }
    }
  }
  a {
    color: white;
    text-decoration: none;
  }
`;
const UserSection = styled.div`
  line-height: 3rem;
  margin: 0 1rem;
  padding-top: 2.5rem;
  @media print {
    padding-top: 0;
    line-height: auto;
    a {
      color: #000;
    }
  }
`;
const LogoutLink = styled.span`
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media print {
    display: none;
  }
`;
const Divider = styled.span`
  margin: 0 8px;
  padding-right: 1px;
  background: #ddd;
  @media print {
    display: none;
  }
`;

const Header = props => {
  const { firebase, user } = useContext(FirebaseContext);
  const intl = useIntl();

  const { siteTitle, domain } = props;
  const handleLogoutClick = () => {
    firebase.logout().then(() => navigate(-1));
  };

  return (
    <StyledHeader>
      <UserSection>
        {!user || !user.userId ? (
          <>
            <Link to="/login" title={intl.formatMessage({ id: "login" })}>
              <FormattedMessage id="login" />
            </Link>
            <Divider />
            <Link to="/" title="Home">
              🏠
            </Link>
          </>
        ) : (
          <>
            <span>
              <FormattedMessage id="hi" />{" "}
              <Link to="/profile">{user.name || user.email}</Link>!
            </span>
            <Divider />
            <LogoutLink onClick={handleLogoutClick}>
              <FormattedMessage id="logout" />
            </LogoutLink>
            <Divider />
            <Link to="/" title="Home" className="home">
              🏠
            </Link>
          </>
        )}
      </UserSection>

      <h1>
        <Link to="/" title={intl.formatMessage({ id: "nice_to_meet_you" })}>
          <span>&lt;{siteTitle}</span>
        </Link>
      </h1>
    </StyledHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
