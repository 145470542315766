import { useEffect, useState } from "react";
import getFirebaseInstance from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { navigate } from "gatsby";

function useAuth() {
  const [user, setUser] = useState(null);
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe;
    let publicProfileUnsubscribe;

    const firebaseInstance = getFirebaseInstance();
    setFirebase(firebaseInstance);

    unsubscribe = onAuthStateChanged(firebaseInstance.auth, userResult => {
      if (userResult) {
        firebaseInstance.getUserProfile(userResult.email).then(userProfile => {
          setUser(userProfile);
        });
      } else {
        if (publicProfileUnsubscribe) {
          publicProfileUnsubscribe();
        }
        setUser(null);
      }

      setLoading(false);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return { user, firebase, loading };
}

export default useAuth;
